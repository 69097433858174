<template>
  <v-navigation-drawer
    v-model="drawer"
    :mini-variant.sync="mini"
    app
  >
    <!-- Company Logo  -->
    <div class="top-container tw-z-0">
      <v-list flat>
        <v-list-item class="" :class="{'tw-transition-all tw-delay-100': !mini, 'tw-transition-all tw-delay-75 tw-px-2': mini}" two-line>
					<v-list-item-avatar tile class="tw-cursor-pointer">
            <v-img src="@/assets/logo.svg"></v-img>
          </v-list-item-avatar>
          <v-list-item-content class="tw-text-left">
            <v-list-item-title class="tw-uppercase gray--text">Micro Center</v-list-item-title>
            <v-list-item-subtitle>
              <TextSecondary class="gray--text">V 1.0.0</TextSecondary>
            </v-list-item-subtitle>
          </v-list-item-content>
					<v-list-item-action>
            <!-- <v-icon>mdi-menu-down</v-icon> -->
            <v-btn
              v-if="!hideMiniToggle"
              class="tw-hidden xl:tw-block"
              icon
              @click.stop="doToggleMini"
            >
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
      <!-- User Account -->
      <v-list class="tw-pt-0">
        <v-container v-if="!mini" class="tw-transition-all tw-delay-75">
          <v-row class="tw-justify-end tw-mr-3">
            <v-col class="tw-text-right tw-pr-0">
              <v-chip color="accent">
                Admin
              </v-chip>
            </v-col>
            <v-col cols="2">
              <v-icon>mdi-cog</v-icon>
            </v-col>
            <v-col cols="2">
              <v-badge
                bordered
                color="error"
                dot
                overlap
              >
                <v-icon>fa-bell</v-icon>
              </v-badge>
            </v-col>
          </v-row>
        </v-container>
        <v-list-item class="tw-px-2">
          <v-list-item-avatar>
            <v-img src="https://cdn.vuetifyjs.com/images/john.png"></v-img>
          </v-list-item-avatar>
          <v-list-item-content class="tw-text-left tw-py-0">
            <v-list-item-title class="title">
              {{`${admin.first_name || ''} ${admin.last_name || ''}`}}
            </v-list-item-title>
            <v-list-item-subtitle>{{admin.email_address}}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <!-- <v-divider></v-divider> -->
    </div>
    
    <!-- Side Nav -->
    <v-list
      class="tw-relative"
      :class="{' tw-top-52':!mini, 'tw-transition-all tw-delay-75 tw-top-40': mini}"
      nav
      dense
    >
      <template v-for="(item, i) in items">
        <!-- v-model="selectedItem" -->
        <v-list-item
          v-if="!item.subs"
          :key="i"
          :to="item.to"
          link
        >
          <v-list-item-icon>
            <v-icon v-if="item.icon" v-text="item.icon"></v-icon>
            <v-img v-else :src="item.iconImage" />
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title class="tw-text-left disabled--text"> 
              {{item.text}}
              <!-- <TextNormal class="">{{item.text}}</TextNormal> -->
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        
        <v-list-group
          v-else
          :value="false"
          :key="i"
          no-action
          :prepend-icon="item.icon"
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title class="tw-text-left" >
                <TextNormal>{{item.text}}</TextNormal>
              </v-list-item-title>
            </v-list-item-content>
          </template>

          <template v-for="(subs, subIdx) in item.subs">

            <v-list-item
              v-if="!subs.subs"
              :key="subIdx"
              link
            >
              <v-list-item-title class="tw-text-left">
                <TextNormal>{{subs.text}}</TextNormal>
              </v-list-item-title>
            </v-list-item>

            <v-list-group
              v-if="subs.subs"
              :key="subIdx"
              :value="false"
              no-action
              sub-group
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title class="tw-text-left">
                    <TextNormal>{{subs.text}}</TextNormal>
                  </v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item
                v-for="([title, icon], i) in subs.subs"
                :key="i"
                link
              >
                <v-list-item-title>
                  <TextNormal>{{title}}</TextNormal>
                </v-list-item-title>

                <v-list-item-icon>
                  <v-icon v-text="icon"></v-icon>
                </v-list-item-icon>
              </v-list-item>
            </v-list-group>
          </template>
        </v-list-group>
      </template>
      
    </v-list>
   
    <template v-slot:append>
        <div class="tw-float-left tw-ml-3 logout">
          <v-btn 
            icon
            @click="signOut()">
            <v-icon>fas fa-sign-out-alt</v-icon>            
          </v-btn>
          <span class="Inter disabled--text tw-ml-6 tw-text-sm">Logout</span> 
        </div>
      </template>
  </v-navigation-drawer>
</template>

<script>
import { toUrl } from '@/router/Index'

import {
  ROUTE_DASHBOARD,
  ROUTE_USERS,
  ROUTE_TECH_BAR,
  ROUTE_STORE_SIGNS,
  ROUTE_AISLE_GUIDE,
  ROUTE_BUILD_YOUR_OWN,
  ROUTE_SURVEY,
} from '@/constants'

import {
  mapState,
  mapMutations,
} from 'vuex'
import { TOGGLE_MINI, TOGGLE_DRAWER } from '@/store/modules/app'


//components
import TextNormal from '@/components/atoms/TextNormal';
import TextSecondary from '@/components/atoms/TextSecondary';


export default {
  name: 'DashboardCoreDrawer',
  props : ['hideMiniToggle'],
  components: {
    TextNormal,
    TextSecondary
  },
  data: () => ({
    selectedItem: 0,
    items: [
      { text: 'Dashboard', 
        icon: 'mdi-view-dashboard', 
        to: toUrl(ROUTE_DASHBOARD) 
      },
      {
        text: 'Users',
        icon: 'mdi-account-circle',
        to: toUrl(ROUTE_USERS),
        
        // subs: [
        //   {
        //     text: 'Admin',
        //     subs: [
        //       ['Management', 'mdi-account-multiple-outline'],
        //       ['Settings', 'mdi-cog-outline'],
        //     ],
        //   },
        //   {
        //     text: 'Employees',
        //     subs: null,
        //   }
        // ], 
      },
      { text: 'Aisle Guides',
        icon: 'mdi-cart',
        to: toUrl(ROUTE_AISLE_GUIDE),
      },
      // { text: 'Products', // to be added
      //   icon: 'mdi-cart',
      //   to: toUrl(ROUTE_PRODUCTS),
      // },
      {
        text: 'Tech Bar Queue',
        icon: 'mdi-memory',
        to: toUrl(ROUTE_TECH_BAR)
      },
      {
        text: 'Build Your Own',
        icon: 'mdi-monitor-multiple',
        to: toUrl(ROUTE_BUILD_YOUR_OWN)
      },
      {
        text: 'Store Signs',
        icon: 'mdi-laptop-mac',
        to: toUrl(ROUTE_STORE_SIGNS),
      },
      {
        text: 'Survey',
        icon: 'mdi-clipboard-edit-outline',
        to: toUrl(ROUTE_SURVEY),
      },
    ],
  }),
  computed: {
    ...mapState({
      admin: (state) => state.app.admin,
      // state here
    }),
    drawer: {
      get () {
        const { state } = this.$store;
        return state.app.drawer
      },
      set (val) {
        const {commit} = this.$store
        commit(TOGGLE_DRAWER, val)
        if(val) {
          commit(TOGGLE_MINI, false)
        }
      },
    },
    mini: {
      get () {
        const { state } = this.$store;
        return state.app.mini
      },
      set (val) {
        const {commit} = this.$store
        commit(TOGGLE_MINI, val)
      },
    },
  },
  methods: {
    ...mapMutations({
      // setDrawer: 'SET_DRAWER',
      // setMini: 'SET_MINI',
    }),
    doToggleMini () {
      this.$store.commit(TOGGLE_MINI)
    },
    signOut(){
      this.$router.push({ path: '/signout'}).catch(error => {
        console.info(error.message)
      });
    }
  },
}
</script>

<style lang="sass" scoped>
.top-container
  @apply tw-bg-white tw-border-0 tw-border-gray-lightest tw-border-opacity-50 tw-border-r tw-border-solid tw-fixed tw-shadow tw-z-10

// list-item-active
.v-list
  .v-list-item--active
    color: $primary !important
    .v-list-item__title
      color: $primary !important

// list-item-not-active
.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled)
  .v-list-item__icon
    .v-icon
      color: $disabled

// logout 
.logout 
  .v-btn > .v-btn__content .v-icon
    color: $disabled
</style>